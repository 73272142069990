/*
  SPACING SYSTEM (px)
  2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

  FONT SIZE SYSTEM (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

 */

/* MAX PHOTO SIZE = 378 x 378px */

/*///////////////////////////// 
******GLOBAL785 RULES******
/////////////////////////////*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  /* font-size: 30%; */
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #343a40;
  line-height: 1;
}

.container {
  max-width: 120rem; /*STANDARD 1200px*/
  padding: 0 2rem;
  margin: 0 auto;
}

.heading-primary {
  display: flex;
  justify-content: center;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.1rem;
  margin-bottom: 6.4rem;
  color: #98320b;
}

.heading-secondary {
  font-size: 3rem;
  margin-bottom: 6.4rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
  color: #495057;
}

.subheading {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  margin-left: 1.2rem;
  text-transform: uppercase;
  color: #f76707;
}

.btn {
  background: none;
  height: 6rem;
  width: 3.5rem;
  border-radius: 5%;
  cursor: pointer;
  border: none;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.2);
  background-color: #848b92bb;
}

.btn:hover {
  background: #ff922b;

  color: #343a40;
}

.btn:active {
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.4);
  background: #f76707;
}

.btn-icon {
  font-size: 2rem;
}

.btn:active .btn-icon {
  color: #495057;
}

/*///////////////////////////// 
********MAIN NAV SECTION********
/////////////////////////////*/

.header {
  align-items: center;
  background-image: url('/img/river-1.jpg');
  background-size: cover;
  background-position: 0 10%;
  height: 42rem;
  opacity: 0.8;
}

.main-nav-box {
  margin: 0 -2rem;
}

.main-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(80, 72, 65, 0.376);
  height: 6rem;
  width: 100%;
  padding: 1.2rem 2.4rem;
  gap: 2.4rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none;
  color: #ffc078;
}

.main-nav-link:hover {
  color: #f76707;
}
.main-nav-link:active {
  color: #e8590c;
}

.main-nav-link-bold {
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  color: #343a40;
  background-color: #ffc078;
  padding: 0.7rem;
  border-radius: 5%;
}

.main-nav-link-bold:hover {
  background-color: #f76707;
}

.main-nav-link-bold:active {
  background-color: #e8590c;
}

.logo {
  height: 3rem;
  color: #000;
  background-color: aqua;
}

/*///////////////////////////// 
*********HERO SECTION*********
/////////////////////////////*/

.section-hero {
  background-color: #fff2e3;
  padding: 8rem 0 11.2rem;
}

.hero {
  margin: 0 auto;
  padding: 0 3.2rem;
  display: flex;
  gap: 9.6rem;
  align-items: center;
}

.hero-text-box {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  color: #495057;
}

.hero-img {
  width: 64rem;
  height: 36rem;
  border-radius: 1%;
}

/*///////////////////////////// 
********DETAILS SECTION********
/////////////////////////////*/
.section-details {
  padding: 9.6rem 0 12.8rem;
}

.details-heading-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
}

.details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: top;
  margin-bottom: 8rem;
}

.details-card-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.6;
  margin: 1rem 0 2rem;
  text-align: center;
  color: #343a40;
}

.details-card {
  width: 34rem;
  height: 41rem;
  background-color: #fffbf7;
  padding: 2.4rem 2.8rem 4.8rem;
  border-radius: 5%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

.details-list {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  list-style: none;
  color: #484e53;
}

.details-span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #f76707;
}

.details-icon {
  color: green;
  height: 1.8rem;
}

/*///////////////////////////// 
********SLIDER SECTION********
/////////////////////////////*/
/* outside of slider section because overflow=hidden */
.slider-text-container {
  display: flex;
  align-items: center;
}
.slider-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-heading {
  margin-bottom: 6.4rem;
}

.slider-heading-span {
  align-self: flex-start;
}

.slider-span {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  color: #68625b;
}

.slider-box {
  overflow: hidden;
  /* overflow: scroll; */
  position: relative;
  height: 35rem;
}

/* .slider-box::-webkit-scrollbar {
  display: none;
} */

.slider {
  margin: 0 4.5rem;
}

.slide {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
  width: 20rem;
  transition: transform 1s;
  cursor: pointer;
  border-radius: 2%;
  background-color: gray;
}

.slide:hover,
.slide:active {
  transition: all, 0.3s;
  transform: scale(1.15);
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.25);
}

.btn-slider-left,
.btn-slider-right {
  position: absolute;
  z-index: 100;
  transform: translateY(75%);
}

.btn-slider-left {
  left: 0.5rem;
}

.btn-slider-right {
  right: 0.5rem;
}

.slider-dots {
  display: flex;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 1.6rem;
  padding-bottom: 3rem;
}

.slider-dot {
  border-radius: 50%;
  background-color: #f767073b;
  border: none;
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
  transition: all 0.5s;
}

.slider-dot:hover,
.slider-dot:active {
  transition: all, 0.3s;
  transform: scale(1.4);
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.25);
}

.slider-dot--active {
  background-color: #888;
  opacity: 1;
}

/*///////////////////////////// 
**********IMAGE MODAL**********
/////////////////////////////*/

.modal {
  position: fixed;
  display: flex;
  top: 50vh;
  left: 45vw;
  transform: translate(-50%, -50%);
  width: 72rem;
  height: 54rem;
  background-size: cover;
  z-index: 1000;
  transition: all 0.3s;
}

.modal-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-img {
  border-radius: 1%;
}

.btn-modal {
  background-color: #495057;
  height: 7rem;
  width: 4rem;
  border: none;
}

.btn-modal-left,
.btn-modal-right {
  height: 8rem;
  width: 4.5rem;
}

.btn-modal-left {
  margin-right: 1.5rem;
}

.btn-modal-right {
  margin-left: 1.5rem;
}

.btn-modal-close {
  position: fixed;
  right: -28%;
  top: -14%;
  height: 4rem;
  width: 4rem;
  background-color: rgba(236, 101, 77, 0.596);
}

.btn-modal-close:hover,
.btn-modal-close:active {
  background-color: rgb(236, 101, 77);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

/* //////////////////////////////
***************CTA***************
///////////////////////////////*/

.section-cta {
  background-color: #fff4e6;
  padding: 9.6rem 0 6.4rem;
}

.cta-heading-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 8rem 6.4rem;
}

.cta-heading {
  margin-bottom: 1rem;
}

.cta-subheading {
  color: #e8590c;
}

.cta-box {
  display: flex;
}

/* //////////////////////////////
*************CONTACT*************
///////////////////////////////*/

.contact-section {
  max-width: 60rem;
  max-height: 35rem;
  border-radius: 2.5%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  background-color: #e9ecef;
  align-self: center;
}

.contact-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 2.4rem;
  padding: 4rem 2rem 3rem;
  justify-content: center;
}

.contact-info {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding: 0.8rem;
  height: 3.5rem;
  width: 23rem;
  border: 0.5px solid rgba(105, 86, 63, 0.863);
}

.contact-name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: flex-start;
}
.contact-email {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.contact-phone-number {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  align-self: flex-end;
}

.contact-message {
  font-family: 'Montserrat', sans-serif;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  width: 29rem;
  height: 12rem;
}

.arrive-depart {
  display: flex;
  column-gap: 4rem;
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.date-arrive,
.date-depart {
  max-width: 12.5rem;
  max-height: 3.4rem;
  margin-top: 0.3rem;
}

.date-arrive-label,
.date-depart-label {
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
}

.checkbox {
  grid-column: 1 / 3;
  grid-row: 4 / 5;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
}

.check-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 4.8rem;
}

.contact-email-checkbox {
  height: 1.5rem;
}

.btn-contact-submit {
  font-family: 'Montserrat', sans-serif;
  padding: 0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #e9ecef;
  background-color: #f76707;
  border: none;
  border-radius: 8%;
  cursor: pointer;
}

.btn-contact-submit:hover,
.btn-contact-submit:active {
  background-color: #d9480f;
}

/*///////////////////////////// 
***********CALENDAR ***********
/////////////////////////////*/

.calendar-section {
  max-width: 45rem;
  max-height: 60rem;
  padding: 3.2rem 2rem;
  margin-bottom: 3.4rem;
  background-color: #e9ecef;
  box-shadow: 0 1rem 1.2rem rgba(0, 0, 0, 0.1);
  border-radius: 2.5%;
}

/* .availability {
  display: flex;
  justify-content: center;
  font-size: 3.6rem;
  margin-bottom: 2.4rem;
  color: #495057;
} */

/* 
.calendar-span {
  font-weight: 600;
} */

table,
tbody th,
td {
  border: 1px solid #f4f6f7;
  border-collapse: collapse;
}

.day-fill td {
  padding: 0.2rem 0 0 0.2rem;
  border-radius: 5%;
  cursor: pointer;
  height: 3.5rem;
}

.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.calendar {
  height: 70%;
  width: 80%;
  background-color: #f4f6f7;
  table-layout: fixed;
  box-shadow: 0 1rem 2.4rem rgba(0, 0, 0, 0.1);
}

.month-year-heading {
  background-color: #f4f6f7;
  font-size: 2.4rem;
  border-bottom: 1px solid #343a40;
  height: 5rem;
  color: #495057;
}

.day-heading {
  background-color: #f4f6f7;
  font-size: 1.4rem;
  border-bottom: 1px solid #343a40;
  height: 3rem;
  color: #495057;
}

.day-fill {
  font-size: 1rem;
  font-weight: 700;
  vertical-align: top;
  background-color: #69db7c;
}

.no-fill {
  background-color: #9ea5ab;
  border: 1px solid #f8f9fa;
}

.available {
  background-color: #69db7c;
}

.booked {
  background-color: #f06a6a;
}

.available-box {
  background-color: #69db7c;
}

.booked-box {
  background-color: #f06a6a;
}

.available-box,
.booked-box {
  height: 1.8rem;
  width: 1.6rem;
  margin-right: 1rem;
}

.calendar-legend {
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  justify-content: space-around;
}

.calendar-legend-available,
.calendar-legend-booked {
  display: flex;
  flex-direction: row;
}

.current-day {
  border: 2px solid rgba(6, 109, 3, 0.555);
}

.current-day.booked {
  border: 2px solid rgba(207, 5, 5, 0.609);
}

.btn-calendar-left {
  margin-right: 1.2rem;
}

.btn-calendar-right {
  margin-left: 1.2rem;
}

.btn-calendar-left,
.btn-calendar-right {
  height: 3.5rem;
  width: 2.5rem;
}
.btn-calendar-left .btn-icon,
.btn-calendar-right .btn-icon {
  font-size: 1.6rem;
}

.jump-to {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.4rem;
  gap: 2.4rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: #495057;
}

.month-select,
.year-select {
  font-family: 'Montserrat', sans-serif;
}
.month-select {
  width: 10rem;
}

.year-select {
  width: 6rem;
}

.btn-jump {
  font-family: 'Montserrat', sans-serif;
  position: static;
  padding: 0.3rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #e9ecef;
  background-color: #f76707;
  border: none;
  border-radius: 8%;
  cursor: pointer;
}

.btn-jump:hover,
.btn-jump:active {
  background-color: #d9480f;
}

/*///////////////////////////// 
************FOOTER************
/////////////////////////////*/
.footer {
  background-color: #495057;
  height: 5rem;
}
